<template>
  <div class="relative flex items-start" :class="{ 'intro-x': hasAnimation }">
    <slot name="avatar">
      <VImage
        :src="getAvatarURL(review?.written_by?.avatar?.view_path)"
        :name="
          `${review?.written_by?.firstname} ${review?.written_by?.lastname}`
        "
      />
    </slot>

    <div class="box px-5 py-3 ml-4 flex-1">
      <div class="flex items-center">
        <div class="font-medium">
          <slot name="name">
            {{ review?.written_by?.firstname }}
            {{ review?.written_by?.lastname }}
          </slot>
        </div>

        <div class="text-xs text-gray-500 ml-auto">
          {{ formatDate({ date: review?.updated_at, displayTime: true }) }}
        </div>
      </div>

      <div class="text-gray-600 mt-1">
        {{ trimHTML(review?.comment) }}
      </div>
    </div>
  </div>
</template>

<script>
// Composables
import useAnimation from "@/composables/useAnimation";
import useDisplay from "@/composables/useDisplay";
// Components
import VImage from "@/components/VImage";

export default {
  components: {
    VImage
  },
  props: {
    review: {
      type: Object,
      required: true
    }
  },
  setup() {
    // Composables
    const { getAvatarURL, trimHTML, formatDate } = useDisplay();
    const { hasAnimation } = useAnimation();

    return {
      // useDisplay
      getAvatarURL,
      formatDate,
      trimHTML,
      // useAnimation
      hasAnimation
    };
  }
};
</script>
