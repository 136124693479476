<template>
  <div>
    <VTitle :title="$t('app.latest_feedback')" class="overview-title" />

    <VAlert
      v-if="feedback.length === 0"
      :text="$t('app.no_latest_feedbacks')"
    />

    <div v-else class="relative">
      <div
        v-for="(review, index) in feedback"
        :key="review.id"
        :class="{ 'mb-3': index !== feedback.length - 1 }"
      >
        <FeedbackReview :review="review">
          <template v-if="$slots.avatar" #avatar>
            <slot name="avatar" :review="review" />
          </template>

          <template v-if="$slots.name" #name>
            <slot name="name" :review="review" />
          </template>
        </FeedbackReview>
      </div>
    </div>
  </div>
</template>

<script>
import FeedbackReview from "@/components/FeedbackReview";
import VAlert from "@/components/VAlert";
import VTitle from "@/components/VTitle";

export default {
  components: {
    FeedbackReview,
    VAlert,
    VTitle
  },
  props: {
    feedback: {
      type: Array,
      default: () => []
    }
  }
};
</script>
